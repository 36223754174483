import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { TopNavigation } from './navigations/TopNavigation'
import {  useSelector } from "react-redux";
import {useNavigationInfo} from './Hooks/useNavigationInfo'
import { useHistory } from 'react-router-dom'
import {useProvideAuth} from './Hooks/useProvideAuth'
export const Layout = (props) => {
  const history=useHistory();
  const state = useSelector(state => state); 
  const {menuList,activeNavigation} = state.general;
  const [formName,title]=useNavigationInfo(history,activeNavigation,menuList);
  const authInfo=useProvideAuth();
  let pathName=history.location.pathname;
  return (
    <React.Fragment>
      {
        authInfo.getUser()===null?<div className="col-12 pt-5 pb-5 align-items-center justify-content-center">{props.children} </div>:<React.Fragment>
           <NavMenu />
      <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
        <TopNavigation />
        <div className="main-content-container container-fluid px-4">
          <div className="page-header row no-gutters py-4">
            <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
              <span className="text-uppercase page-subtitle">{title}</span>
              {/* <h3 className="page-title">Blog Overview</h3> */}
            </div>
          </div>
          {props.children}
        </div>
      </main>
      </React.Fragment>
      }
     
    </React.Fragment>
  );

}
