import React, { Component, useEffect } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom'
import { menuService } from '../redux/services'
import * as reqTypes from '../redux/actionTypes'

const NavMenu = () => {
  const dispatch = useDispatch();
  const hostory = useHistory();
  const state = useSelector(state => state);
  const { menuList, activeNavigation } = state.general;
  return (
    <aside className="main-sidebar col-12 col-md-3 col-lg-2 px-0">
      <div className="main-navbar">
        <nav className="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
          <a className="navbar-brand w-100 mr-0" href="#" style={{ lineHeight: 25 }}>
            <div className="d-table m-auto">
              <img id="main-logo" className="d-inline-block align-top mr-1" style={{ width: '50%', marginLeft: '2rem', height: '3rem' }} src="/images/elvetham-logo-sm-01.svg" alt="ELVETHAM" />
              <span className="d-none d-md-inline ml-1">ELVETHAM</span>
            </div>
          </a>
          <a className="toggle-sidebar d-sm-inline d-md-none d-lg-none">
            <i className="material-icons">&#xE5C4;</i>
          </a>
        </nav>
      </div>
      <div className="nav-wrapper">
        <ul className="nav flex-column">
          {menuList && menuList.map((item, index) => {
            return <li key={index} className="nav-item" onClick={e => {
              hostory.push(item.url, item);
              dispatch({ type: reqTypes.NAVIGATION, payload: item });
            }}>
              <a className={["nav-link", ((activeNavigation && activeNavigation === item) || (hostory && hostory.location.pathname === item.url)) ? "active" : ""].join(" ")} href={void (0)}>
                <i className="material-icons">{item.icon}</i>
                <span>{item.text}</span>
              </a>
              {/* <div className="dropdown-menu dropdown-menu-small " x-placement="bottom-start" >
                <a class="dropdown-item " href="file-manager-list.html">Files - List View</a>
                <a class="dropdown-item " href="file-manager-cards.html">Files - Cards View</a>
              </div> */}
            </li>
          })}
        </ul>
      </div>
    </aside>

  );

}
export { NavMenu };