import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";
import { PublicKey } from "../../StaticSources";
var siteInterval = null;
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const history = useHistory();
  const getUser = () => {
    var sessionInfo = sessionStorage.getItem("user");
    if(sessionInfo){
      var dcJson=JSON.parse(sessionInfo);
      return dcJson.Name;
    }
    return null;
  }
  if (getUser() != null) {
    siteInterval = window.setInterval(() => {
      if (getUser() === null) {
        window.location.href = "/";
        window.clearInterval(siteInterval);
      }
    }, 100);
  }

  const signin =(ec,usr,cb) => {
    sessionStorage.setItem("user", usr);
    sessionStorage.setItem("_ect", ec);
   
    window.setTimeout(() => { window.location.href = "/"; }, 200);
  };
  const getToken =() => {
    var _ect = sessionStorage.getItem("_ect");
   if(_ect){
    let data=  AES.decrypt(_ect,PublicKey);
    var token=null;
    if(data){
      let tokenString=data.toString(CryptoJS.enc.Utf8);
      if(tokenString){
        token=JSON.parse(tokenString);
      }
      return token;
    }
    
    return null;
   }
  return null;
  };
  
  const signout = cb => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("_ect");
    window.setTimeout(() => { window.location.href = "/login"; }, 200);
  };

  return {
    user,
    signin,
    signout,
    getUser,
    getToken
  };
}
export { useProvideAuth };