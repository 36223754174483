import { OFFER_LIST,OFFER_VIEW ,OFFER_FILTER_UPDATE,OFFER_FILTER_SEARCH,ACTIVE_OFFER} from "../actionTypes";
const initialState = {   
   OfferView:'LIST',
   selectedOffer:null,
   offers:null,
   filterSource:{status:'',searchTerm:''},
   Loading:true,
   refresh:false
};
function reducer(state = initialState, action) {
  debugger;
    switch (action.type) {
      case "reset":
        return initialState;
        case OFFER_VIEW: 
        if( action.payload==='SUCCESS'){
          action.payload="LIST";
          state.refresh=true;
        }
       if(action.payload==="LIST"){
        state.selectedOffer=null;
       }
        return {
            ...state,
            OfferView: action.payload
          };
       case OFFER_FILTER_UPDATE: 
        return {
            ...state,
            filterSource: action.payload
          };
    case OFFER_LIST:
            return {
              ...state,
              offers: action.payload,
              selectedOffer:null,
              Loading:false,
              refresh:false,
              OfferView:'LIST'
            };
     case ACTIVE_OFFER:
              return {
                ...state,
                selectedOffer:action.payload,
                Loading:false,
                refresh:false,
                OfferView:'EDIT'
              };
        case OFFER_FILTER_SEARCH:
            return {
              ...state,
              offers: action.payload.data,
              filterSource: action.payload.filterSource,
              selectedOffer:null,
              Loading:false,
              refresh:false,
              OfferView:'LIST'
            };
      default:
        return state;
    }
  }

  export {reducer as default}