import { ROOM_LIST,ROOM_VIEW ,ROOM_FILTER_UPDATE,ROOM_FILTER_SEARCH,ACTIVE_ROOM,PMS_CODES,SET_AMENITIES} from "../actionTypes";
const initialState = {   
   RoomView:'LIST',
   selectedRoom:null,
   rooms:null,
   pmsCodes:null,
   amenities:null,
   filterSource:{status:'',searchTerm:''},
   Loading:true,
   refresh:false
};
function reducer(state = initialState, action) {
    switch (action.type) {
      case "reset":
        return initialState;
        case ROOM_VIEW: 
        if( action.payload==='SUCCESS'){
          action.payload="LIST";
          state.refresh=true;
        }
       if(action.payload==="LIST"){
        state.selectedRoom=null;
       }
        return {
            ...state,
            RoomView: action.payload
          };
       case ROOM_FILTER_UPDATE: 
        return {
            ...state,
            filterSource: action.payload
          };
          case PMS_CODES: 
          return {
              ...state,
              pmsCodes: action.payload
            };
            case SET_AMENITIES: 
            return {
                ...state,
                amenities: action.payload
              };
    case ROOM_LIST:
            return {
              ...state,
              rooms: action.payload,
              selectedRoom:null,
              Loading:false,
              refresh:false,
              RoomView:'LIST'
            };
     case ACTIVE_ROOM:
              return {
                ...state,
                selectedRoom:action.payload,
                Loading:false,
                refresh:false,
                RoomView:'EDIT'
              };
        case ROOM_FILTER_SEARCH:
            return {
              ...state,
              rooms: action.payload.data,
              filterSource: action.payload.filterSource,
              selectedRoom:null,
              Loading:false,
              refresh:false,
              RoomView:'LIST'
            };
      default:
        return state;
    }
  }

  export {reducer as default}