import { NAVIGATION,MENULIST} from "../actionTypes";
const initialState = { 
   activeNavigation:'',
   menuList:[]
};
function reducer(state = initialState, action) {
    switch (action.type) {
      case "reset":
        return initialState;
      case NAVIGATION:
        return {
          ...state,
          activeNavigation: action.payload
        };
        case MENULIST:
          return {
            ...state,
            menuList: action.payload
          };
      default:
        return state;
    }
  }

  export {reducer as default}