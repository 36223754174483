import { BLOG_CATEGORIES,BLOG_DATA,BLOG_VIEW, CHANGE,BLOG_LIST,BLOG_SELECTION,BLOG_UPDATE,BLOG_SEL_CTGRY,FILTER_SEARCH} from "../actionTypes";
const initialState = {    
   categories:null,
   selectedCategory:[],
   selectedBlog:null,
   blogData:null,
   blogs:null,
   BlogView:'LIST',
   IsSaved:false,
   Loaded:false,
   refresh:false,
   filterSource:{status:'',searchTerm:''},
};
function reducer(state = initialState, action) {
  //console.log(action);
    switch (action.type) {
      case "reset":
        return initialState;
      case BLOG_CATEGORIES:
        return {
          ...state,
          categories: action.payload.categories,
          selectedCategory: action.payload.selectedCategories,
          IsSaved: false
        };
        case BLOG_DATA:
          return {
            ...state,
            blogData: action.payload
          };
          case 'LOADING':
            return {
              ...state,
              Loading: action.payload
            };

          case BLOG_VIEW:
            let selectedCategory=state.selectedCategory;
            if(action.payload==="NEW"){
              state.selectedBlog=null;
              action.payload="DETAIL";
            }
            if(action.payload==="LIST"){
              state.refresh=true;
              selectedCategory=[];
            }
            return {
              ...state,
              BlogView: action.payload,
              selectedCategory            
            };
            case BLOG_LIST:
              return {
                ...state,
                blogs: action.payload,
                selectedBlog:null,
                selectedCategory:[],
                refresh:false,
                Loaded:true
              };
              case BLOG_SELECTION:
                return {
                  ...state,
                  selectedBlog: action.payload,
                  BlogView:'DETAIL',
                  Loaded:true
                };
                case BLOG_SEL_CTGRY:
                  return {
                    ...state,
                    selectedCategory: action.payload,
                    Loaded:true
                  };
                case BLOG_UPDATE:
                  return {
                    ...state,
                    selectedBlog: action.payload,
                    Loaded:true
                  };
            case FILTER_SEARCH:
              return {
                ...state,
                blogs: action.payload.data,
                filterSource: action.payload.filterSource,
                selectedBlog:null,
                Loading:false,
                BlogView:'LIST'
              };
            case CHANGE:
              return {
                ...state,
                IsSaved: action.payload
              };
      default:
        return state;
    }
  }

  export {reducer as default}