import { PAGE_LIST,PAGE_VIEW ,FILTER_UPDATE,FILTER_SEARCH,ACTIVE_PAGE,LOAD_CATEGORIES} from "../actionTypes";
const initialState = {   
   PageView:'LIST',
   selectedPage:null,
   pages:null,
   filterSource:{status:'',searchTerm:''},
   enqCategories:null,
   Loading:true
};
function reducer(state = initialState, action) {
  
    switch (action.type) {
      case "reset":
        return initialState;
        case PAGE_VIEW: 
        return {
            ...state,
            PageView: action.payload
          };
       case FILTER_UPDATE: 
        return {
            ...state,
            filterSource: action.payload
          };
    case PAGE_LIST:
            return {
              ...state,
              pages: action.payload,
              selectedPage:null,
              Loading:false,
              PageView:'LIST'
            };
 case LOAD_CATEGORIES:
              return {
                ...state,
                enqCategories: action.payload
              };
     case ACTIVE_PAGE:
              return {
                ...state,
                selectedPage:action.payload,
                Loading:false,
                PageView:'EDIT'
              };
        case FILTER_SEARCH:
            return {
              ...state,
              pages: action.payload.data,
              filterSource: action.payload.filterSource,
              selectedPage:null,
              Loading:false,
              PageView:'LIST'
            };
      default:
        return state;
    }
  }

  export {reducer as default}