import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { useDispatch, useSelector } from "react-redux";
import {menuService} from './redux/services'
import * as reqTypes from './redux/actionTypes'
import ViewLoader from './components/ViewLoader'
import { useHistory } from 'react-router-dom'
import {useNavigationInfo} from './components/Hooks/useNavigationInfo'


export default ()=> {
  debugger;
  const history=useHistory();
  const dispatch = useDispatch();
  const state = useSelector(state => state); 
  const {menuList,activeNavigation} = state.general;
  history.listen((newLocation, action) => {
    if (action != "PUSH") {
      history.go(1);
      } 
    });
  
  React.useEffect(()=>{
    if(menuList ===null || menuList===undefined || menuList.length<=0){
      dispatch(menuService("",(id,data,error)=>{
        if(!error){      
          return { type: reqTypes.MENULIST, payload:data};;
        }
      }));
    }
  },[]);
  const [formName,formTitle]=useNavigationInfo(history,activeNavigation,menuList);
  
  return (     
    <Layout>
      <Route exact path='/' render={()=><ViewLoader componentName="Home" key="1"></ViewLoader>} />
      <Route exact path='/login' render={()=><ViewLoader componentName="Login" key="1"></ViewLoader>} />
      <Route path='/g/:code' render={()=><ViewLoader componentName={formName} key="2"></ViewLoader>} />
    </Layout>
    
  );
}
