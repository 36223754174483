import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
function useNavigationInfo(history,activeNavigation,menuList) {
    if(!activeNavigation){
        let currentMenu=menuList.find(p=>p.url===history.location.pathname);
        return [currentMenu?currentMenu.form:'Blank',currentMenu?currentMenu.text:"NOT FOUND"];
      }
      return [activeNavigation.form,activeNavigation.text];
  }
 
  export {useNavigationInfo};