export const NAVIGATION = "BOTTOM_NAVIGATION";
export const MENULIST = "MENULIST";
export const CHANGE = "CHANGE";
export const FETCHING = "FETCHING";
export const FETCHING_COMPLETE = "FETCHING_COMPLETE";

export const BLOG_CATEGORIES = "BLOG_CATEGORIES";
export const BLOG_DATA = "BLOG_DATA";
export const BLOG_VIEW = "BLOG_VIEW";
export const BLOG_LIST = "BLOG_LIST";
export const BLOG_UPDATE = "BLOG_UPDATE";
export const BLOG_SEL_CTGRY = "BLOG_SEL_CTGRY";
export const BLOG_SELECTION = "BLOG_SELECTION";
export const BLOG_SAVE_COMPLT = "BLOG_SAVE_COMPLT";

export const STORAGE_FETCH = "STORAGE_FETCH";
export const STORAGE_SET_CURRENT = "STORAGE_SET_CURRENT";


export const PAGE_LIST = "PAGE_LIST";
export const PAGE_VIEW = "PAGE_VIEW";
export const FILTER_UPDATE='FILTER_UPDATE';
export const FILTER_SEARCH='FILTER_SEARCH';
export const ACTIVE_PAGE='ACTIVE_PAGE';
export const LOAD_CATEGORIES='LOAD_CATEGORIES';

export const CONFIG_MENUS = "CONFIG_MENUS";
export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";


export const OFFER_LIST = "OFFER_LIST";
export const OFFER_VIEW = "OFFER_VIEW";
export const ACTIVE_OFFER='ACTIVE_OFFER';
export const OFFER_FILTER_UPDATE='OFFER_FILTER_UPDATE';
export const OFFER_FILTER_SEARCH='OFFER_FILTER_SEARCH';

export const SET_AMENITIES = "SET_AMENITIES";
export const PMS_CODES = "PMS_CODES";
export const ROOM_LIST = "ROOM_LIST";
export const ROOM_VIEW = "ROOM_VIEW";
export const ROOM_REFRESH_VIEW = "ROOM_REFRESH_VIEW";
export const ACTIVE_ROOM='ACTIVE_ROOM';
export const ROOM_FILTER_UPDATE='ROOM_FILTER_UPDATE';
export const ROOM_FILTER_SEARCH='ROOM_FILTER_SEARCH';


export const SUBSCRIBER_LIST = "SUBSCRIBER_LIST";
export const SUBSCRIBER_FILTER_UPDATE='SUBSCRIBER_FILTER_UPDATE';
export const SUBSCRIBER_FILTER_SEARCH='SUBSCRIBER_FILTER_SEARCH';


















