import React, { Suspense } from 'react';
import { Redirect } from "react-router-dom";
import {useProvideAuth} from '../components/Hooks/useProvideAuth'
const ViewLoader = ({componentName})=>{ 
    const authInfo=useProvideAuth();
    if(!authInfo.getUser() && componentName!="Login")
        return <Redirect
                to={{
                    pathname: "/login",
                    state: { from: '' }
                }}
                />
    const WinComponent = React.lazy(() => import(`./pages/${componentName}`));    
    
    return <Suspense fallback={<div className="fall-back-ex">Loading...</div>}>               
               <WinComponent />
           </Suspense>
}
export default ViewLoader;