import { CONFIG_MENUS, SET_ACTIVE_MENU } from "../actionTypes";
const initialState = {
  menus: [

    //  {text:"Logo",active:false,form:'Blank',enabled:false},
    { text: "Top Menu", active: false, form: 'SiteConfig/Menu' },
    { text: "Footer", form: 'SiteConfig/Footer' },
    { text: "Contact Info", form: 'SiteConfig/Contact' },
    { text: "Social Links", active: false, form: 'SiteConfig/SocialMedia' },
    { text: "Google tags", active: false, form: 'SiteConfig/TagManager' },
    //  {text:"Emails",active:false,form:'SiteConfig/Emails',enabled:false},
    { text: "Api Config", form: 'SiteConfig/ApiConfig' },
    { text: "Email Footer", form: 'SiteConfig/EmailFooter' }]
};
function reducer(state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    case "reset":
      return initialState;
    case SET_ACTIVE_MENU:
      return {
        ...state,
        menus: action.payload
      };
    default:
      return state;
  }
}

export { reducer as default }