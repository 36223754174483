
const env = {
    API_URL: 'https://localhost:44369',
    CORS_URL: 'http://localhost:3000',
    PREVIEW: 'https://localhost:44319'
}
//Live
// if (process.env.NODE_ENV === 'production') {
//     env.API_URL = "https://service.cms.gemhotels.com";
//     env.CORS_URL = "https://site-manager.gemhotels.com";
//     env.PREVIEW = 'https://gemhotels.com/'
// }
//Test
if (process.env.NODE_ENV === 'production') {
    env.API_URL = "https://service.cms.gemhotels.com";
    env.CORS_URL = "https://site-manager.gemhotels.com";
    env.PREVIEW = 'https://site9.xeniaone.com/'
}
export { env as ServiceUrls };