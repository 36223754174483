
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import { Provider } from "react-redux";
import store from "./redux/store";
// import $ from 'jquery';
// import Popper from 'popper.js';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import useProvideAuth from './components/Hooks/useProvideAuth'
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#0A3835',
      contrastText:'#F9F2E2'
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#DB5A46',
      light:'rgba(142, 112, 55, 0.67)'
    },
  }
});

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


ReactDOM.render(
  <Provider store={store}>
      <ThemeProvider theme={theme}>
      <BrowserRouter basename={baseUrl}>
          <App />
        </BrowserRouter>
  </ThemeProvider>
  </Provider>,
  rootElement);



