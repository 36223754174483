import { FETCHING, FETCHING_COMPLETE, STORAGE_FETCH, STORAGE_SET_CURRENT } from "../actionTypes";
const initialState = {
    directoryInfo: null,
    currentPath: '/',
    isLoading: false
};

function reducer(state = initialState, action) {
   
    switch (action.type) {
        case FETCHING:
            return {
                ...state,
                isLoading: true
            };
        case FETCHING_COMPLETE:
            return {
                ...state,
                isLoading: false
            };
        case STORAGE_FETCH:
            return {
                ...state,
                directoryInfo: action.payload.data,
                currentPath: action.payload.current,
                isLoading: false
            };
        case STORAGE_SET_CURRENT:
            return {
                ...state,
                currentPath: action.payload,
                isLoading: true
            };
        default:
            return state;
    }
}


export { reducer as default }